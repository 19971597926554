<template>
  <div class="forummid">
    <div class="talkeditor">
      <div class="editraarea">
        <div class="content" ref="content">
          <div class="editarcard">
            <div class="card">
              <div
                ref="inputvalue"
                class="cardinput"
                contenteditable="true"
                spellcheck="false"
                :placeholder="plac"
                @focus="onFocus"
                @blur="onBlur"
                @input="onInput"
              ></div>
            </div>
          </div>
          <div class="bottomwrap"></div>
          <span class="bottom">{{ count }}/1000</span>
        </div>
      </div>
      <div class="btnarea">
        <div class="toobar">
          <div></div>
          <a-button type="primary">发布</a-button>
        </div>
      </div>
    </div>
    <Talkcontent />
  </div>
</template>
<script>
import { ref, reactive, toRefs } from "vue";
import Talkcontent from "./talkcontent.vue";
export default {
  components: {
    Talkcontent,
  },
  setup() {
    const content = ref("");
    const inputvalue = ref("");
    const plac = ref("快和朋友分享有趣的事");
    const data = reactive({
      count: 0,
      context: "",
    });
    function onFocus() {
      content.value.className = "content active";
    }
    function onBlur() {
      content.value.className = "content";
    }
    function onInput() {
      data.count = inputvalue.value.innerText.length;
      if (Number(inputvalue.value.innerText.length)) {
        plac.value = "";
        data.context = inputvalue.value.innerText;
      } else {
        plac.value = "快和朋友分享有趣的事";
        data.context = "";
      }
    }
    return {
      ...toRefs(data),
      inputvalue,
      content,
      plac,
      onFocus,
      onBlur,
      onInput,
    };
  },
};
</script>
<style lang="scss" scoped>
.forummid {
  .talkeditor {
    position: relative;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 12px;
    .editraarea {
      padding: 20px 20px 0;
      position: relative;
      .content {
        position: relative;
        border-radius: 4px;
        border: 1px solid #ccc;
        background-color: #ccc;
        box-sizing: border-box;
        padding-right: 4px;
        .bottom {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          position: absolute;
          right: 8px;
          bottom: 4px;
          z-index: 1;
        }
        .editarcard {
          position: relative;
          margin-bottom: 2px;
          max-height: 180px;
          overflow-y: auto;
        }
        .card {
          .cardinput {
            position: relative;
            font-size: 14px;
            line-height: 24px;
            color: var(--juejin-font-1);
            min-height: 100px;
            outline: none;
            padding: 5px 12px;
            box-sizing: border-box;
            white-space: pre-wrap;
          }
          .cardinput:after {
            content: attr(placeholder);
            position: absolute;
            top: 5px;
            color: #8a919f;
            pointer-events: none;
          }
        }
      }
    }
  }
  .btnarea {
    padding: 19px 20px 27px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .toobar {
      display: flex;
      color: #007fff;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

  .active {
    border-color: #1e80ff !important;
    background-color: #fff !important;
  }
}
</style>
