<template>
  <div class="forumright">
    <div class="userbox">
      <div class="userpro flexmode">
        <div>
          <a-avatar size="large">
            <template #icon><UserOutlined /></template>
          </a-avatar>
        </div>
        <div class="ml5">
          <p>单推丽珍人</p>
        </div>
      </div>
      <div class="status">
        <div>
          <div class="count">10</div>
          <div class="title">话题</div>
        </div>
        <div>
          <div class="count">4</div>
          <div class="title">关注</div>
        </div>
      </div>
    </div>
    <div class="listhotbox">
      <p>精选话题</p>
      <div class="hotlist">
        <div class="hotitem point">
          <div class="hotcontent">《小v生存之道》</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UserOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    UserOutlined,
  },
  setup() {},
};
</script>
<style lang="scss" scoped>
.forumright {
  .userbox {
    background: #fff;
    border-radius: 4px;
    padding: 24px 20px;
    .userpro {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e4e6eb;
      padding-bottom: 20px;
    }
    .status {
      display: flex;
      padding-top: 20px;
      > div {
        padding-right: 27px;
      }
    }
  }
  .listhotbox {
    background: #fff;
    border-radius: 4px;
    margin-top: 16px;
    > p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 56px;
      padding: 0 20px;
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      border-bottom: 1px solid #e4e6eb;
      margin: 0;
    }
    .hotlist {
      padding: 8px 12px;
      .hotitem {
        display: flex;
        padding: 8px;
        .hotcontent {
          transition: color 0.3s;
          font-size: 14px;
          line-height: 24px;
          color: #515767;
          margin: 0;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}
</style>
