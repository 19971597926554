<template>
  <div class="talkcontent">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="热门"></a-tab-pane>
      <a-tab-pane key="2" tab="最新" force-render></a-tab-pane>
    </a-tabs>
    <div class="listwrap">
      <ul>
        <li v-for="(item, index) in listdata" :key="index">
          <div class="pitem">
            <div class="piitemheader">
              <div class="group flexmode">
                <div class="avter">
                  <a-avatar size="large">
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
                </div>
                <div class="avname ml20">
                  <div><p>单推丽珍人</p></div>
                </div>
              </div>
              <div class="action"></div>
            </div>
            <div class="piitemcotent">
              <div class="contentrow point">
                <span
                  >测试话题,测试数据小v如何生存,如何做好直播效果,如何恰米</span
                >
              </div>
            </div>
            <div class="piitemhotuser point">
              <div class="hottitle">热评</div>
              <div class="hotcontent">你是在问我吗?</div>
            </div>
            <div class="piitemother"></div>
            <div class="pinaction">
              <div class="shark action">分享</div>
              <div class="comment action">评论</div>
              <div class="likecomment action">喜欢</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { ref, toRefs, reactive } from "vue";
import { UserOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    UserOutlined,
  },
  setup() {
    const activeKey = ref("1");
    const data = reactive({
      listdata: [{}, {}],
    });
    return {
      ...toRefs(data),
      activeKey,
    };
  },
};
</script>
<style lang="scss" scoped>
.talkcontent {
  .listwrap {
    ul {
      li {
        margin-bottom: 8px;
        .pitem {
          background-color: #fff;
          border-radius: 4px;
          padding: 20px 20px 0;
          position: relative;
          .piitemcotent {
            position: relative;
            margin: 8px 0 0 60px;
            margin-top: 8px;
            .contentrow {
              font-size: 14px;
              color: #17181a;
              overflow: hidden;
              line-height: 20px;
            }
          }
          .piitemhotuser {
            position: relative;
            margin: 8px 0 0 60px;
            margin-top: 12px;
            background: #f7f8fa;
            border-radius: 4px;
            padding: 12px 16px;
          }
          .piitemother {
            position: relative;
            margin: 8px 0 0 60px;
            margin-top: 12px;
            display: flex;
          }
          .pinaction {
            display: flex;
            position: relative;
            height: 36px;
            border-top: 1px solid #e4e6eb;
            color: #86909c;
          }
        }
      }
    }
  }

  .action {
    flex: 1 1 33.333%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    cursor: pointer;
  }
}
</style>
