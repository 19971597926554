<template>
  <div class="forumleft">
    <div class="item">
      <div class="title">最新</div>
    </div>
    <div class="item">
      <div class="title">热门</div>
    </div>
    <div class="item">
      <div class="title">关注</div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>
<style lang="scss" scoped>
.forumleft {
  width: 180px;
  background: #fff;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 99;
  position: fixed;
  // top: 80px;
  transition: all 0.2s linear;
  max-height: calc(100% - 109px);
  overflow: auto;
  .item {
    .title {
      padding: 0 12px;
      color: #252933;
      border-radius: 4px;
      display: flex;
      align-items: center;
      height: 44px;
      margin-bottom: 2px;
      font-size: 16px;
      transition: color 0.3s;
      text-decoration: none;
      cursor: pointer;
    }
    :hover {
      color: #1e80ff !important;
      background: #eaf2ff !important;
    }
  }

  .active {
    color: #1e80ff !important;
    background: #eaf2ff !important;
  }
}
</style>
