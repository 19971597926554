<template>
  <div class="forum animated fadeIn">
    <div class="fleft">
      <Forumleft />
    </div>
    <div class="fmid">
      <Forummid />
    </div>

    <div class="fright">
      <Forumright />
    </div>
  </div>
</template>
<script>
import Forumleft from "./forumpage/forumleft.vue";
import Forummid from "./forumpage/forummid/index.vue";
import Forumright from "./forumpage/forumright.vue";
export default {
  components: {
    Forumleft,
    Forummid,
    Forumright,
  },
  setup() {},
};
</script>
<style lang="scss" scoped>
.forum {
  display: flex;
  justify-content: space-around;
  .fleft {
    width: 180px;
    margin-right: 20px;
    // box-shadow: 0 1px 2px 0;
  }
  .fmid {
    width: 720px;
    max-width: 100%;
    flex-shrink: 0;
    margin-right: 20px;
  }
  .fright {
    width: 260px;
  }
}
</style>
